import {
  ColorConfig,
  TokenData,
  TokenNameDisplayFormat,
  TokenType,
} from '@knapsack/types';
import {
  camelCase,
  containsString,
  hasItemsInItems,
  kebabCase,
  snakeCase,
} from '@knapsack/utils';

function getTokenTypes(tokens: TokenData[]): TokenType[] {
  return [...new Set(tokens.map((t) => t.type))];
}

/**
 * Replaces `-` with `.` in a token's name to get the new token ID.
 * The old "name" of a token was the kebab-case version of the token's path.
 * The old path (`string[]`) never supported `-` in it's strings.
 * We now use `.` to separate the path.
 * Can safely run on an already converted token ID to get the same ID.
 * @see {getOldTokenInfo}
 */
export function convertTokenNameToId(name: string) {
  if (name.includes('.')) {
    // already converted & may include `-` that should not be replaced
    return name;
  }
  return name.replaceAll('-', '.');
}

/**
 * Gets the older properties of a token used from Style Dictionary's CTI.
 * @see {convertTokenNameToId}
 */
export function getOldTokenInfo(token: TokenData) {
  const path = token.id.split('.');
  const [category, ...tags] = path;
  // tags don't include the last item in the path
  tags.pop();
  return {
    path,
    category,
    tags,
    name: kebabCase(token.id),
  };
}

function getCategories(tokens: TokenData[]): string[] {
  return [...new Set(tokens.map((t) => getOldTokenInfo(t).category))];
}

function getTags(tokens: TokenData[]): string[] {
  return [...new Set(tokens.flatMap((t) => getOldTokenInfo(t).tags))];
}

/**
 * @deprecated This is for the old format
 */
export function filterTokens({
  allTokens,
  type,
  category,
  tags,
  name,
  exclude,
}: {
  allTokens: TokenData[];
  type?: TokenType;
  category?: string;
  tags?: string[];
  name?: string;
  exclude?: string;
}): {
  tokens: TokenData[];
  theseTags: string[];
  theseCategories: string[];
  theseTokenTypes: TokenType[];
} {
  let tokens = [...allTokens];

  const theseTokenTypes = getTokenTypes(allTokens).sort();
  if (type) {
    tokens = tokens.filter((t) => t.type === type);
  }

  const theseCategories = getCategories(tokens).sort();

  if (category) {
    tokens = tokens.filter((t) => getOldTokenInfo(t).category === category);
  }

  const theseTags = getTags(tokens).sort();
  if (tags?.length > 0) {
    // only filter by selected tags that fall under selected category
    const tagsInTheseTags = tags.filter((t) => theseTags.includes(t));
    tokens =
      tagsInTheseTags.length > 0
        ? tokens.filter((t) => {
            return hasItemsInItems(getOldTokenInfo(t).tags, tags);
          })
        : tokens;
  }

  if (name) {
    tokens = tokens.filter((t) => {
      return (
        containsString(t.id, name) ||
        containsString(getOldTokenInfo(t).name, name)
      );
    });
  }

  if (exclude) {
    tokens = tokens.filter((t) => {
      return !(
        containsString(t.id, exclude) ||
        containsString(getOldTokenInfo(t).name, exclude)
      );
    });
  }

  return {
    tokens,
    theseTags,
    theseCategories,
    theseTokenTypes,
  };
}

export function convertColorTokenToValue({
  colorToken,
  fallbackColor,
  allTokens = [],
}: {
  colorToken: ColorConfig;
  fallbackColor?: string;
  allTokens?: TokenData[];
}): string {
  if (colorToken && colorToken?.type === 'value') {
    return colorToken.colorValue;
  }

  const colorTokens: TokenData<'color'>[] = allTokens.filter(
    (token): token is TokenData<'color'> => token.type === 'color',
  );

  if (colorToken && colorToken?.type === 'design-token') {
    if ('tokenName' in colorToken) {
      const colorTokenMatch = colorTokens.find((token) => {
        return token.id === convertTokenNameToId(colorToken.tokenName);
      });
      if (colorTokenMatch?.value) {
        return colorTokenMatch.value;
      }
      // @todo: consider updating to be a user-visible warning?
      console.warn(
        `unable to find the color token named ${colorToken.tokenName}, reverting to fallback color instead.`,
      );
    }
  }

  if (fallbackColor) {
    return fallbackColor;
  }

  return undefined;
}

export function convertColorValueToColorConfig({
  colorValue,
  refName,
}: {
  colorValue: string;
  refName?: string;
}): ColorConfig {
  if (refName) {
    return {
      type: 'design-token',
      tokenName: refName,
    };
  }

  return { type: 'value', colorValue };
}

export function convertDimensionValueToNumber({
  dimensionValue,
  rootFontSize = 16,
}: {
  dimensionValue: string;
  rootFontSize?: number;
}): number {
  if (containsString(dimensionValue, 'rem')) {
    return parseFloat(dimensionValue) * rootFontSize;
  }
  return parseFloat(dimensionValue);
}

export function sortDimensionTokens({
  tokens,
  rootFontSize = 16,
}: {
  tokens: TokenData<'dimension'>[];
  rootFontSize?: number;
}): TokenData<'dimension'>[] {
  return tokens.sort(
    (a, b) =>
      convertDimensionValueToNumber({ dimensionValue: a.value, rootFontSize }) -
      convertDimensionValueToNumber({ dimensionValue: b.value, rootFontSize }),
  );
}

// Token NAME has restrictions
// @link https://tr.designtokens.org/format/#character-restrictions
export const tokenNameValidation = {
  dolla: (val: string) =>
    !val.startsWith('$') || 'Name cannot start with dollar sign.',
  dots: (val: string) => !val.includes('.') || 'Name cannot include dots.',
  curly: (val: string) => {
    return (
      !(val.includes('{') || val.includes('}')) ||
      'Name cannot include curly brackets: {}.'
    );
  },
};

export function getTokenDisplayName({
  id,
  format,
}: {
  id: string;
  format?: TokenNameDisplayFormat;
}): string {
  const nameDisplayFormat = format || 'default';

  switch (nameDisplayFormat) {
    case 'default':
      return id;
    case 'android':
      return snakeCase(id);
    case 'cssVar':
      return `--${kebabCase(id)}`;
    case 'less':
      return `@${kebabCase(id)}`;
    case 'scss':
      return `$${kebabCase(id)}`;
    case 'json':
    case 'swift':
      return camelCase(id);
    default: {
      const _exhaustiveCheck: never = nameDisplayFormat;
      throw new Error(`Unknown token name format: ${nameDisplayFormat}`);
    }
  }
}

export function formatCubicBezierOriginalValue(
  originalValue: TokenData<'cubicBezier'>['originalValue'],
): string {
  if (typeof originalValue === 'string') return originalValue;
  return originalValue.join(', ');
}

export function formatBorderOriginalValue(
  originalValue: TokenData<'border'>['originalValue'],
): string {
  if (typeof originalValue === 'string') return originalValue;
  return `${originalValue.color} ${originalValue.style} ${originalValue.width}`;
}

export function formatShadowOriginalValue(
  originalValue: TokenData<'shadow'>['originalValue'],
): string {
  if (typeof originalValue === 'string') return originalValue;
  const { blur, color, inset, offsetX, offsetY, spread } = originalValue;
  return `${
    inset ? 'inset ' : ''
  }${offsetX} ${offsetY} ${blur} ${spread} ${color}`;
}

export function formatStaticTokenDisplayValue(token: TokenData): string {
  if (token.kind === 'ref') throw new Error('Cannot format a ref token');

  const { originalValue, type } = token;

  switch (type) {
    case 'boolean':
      return originalValue ? 'true' : 'false';
    case 'border':
      return formatBorderOriginalValue(originalValue);
    case 'cubicBezier':
      return formatCubicBezierOriginalValue(originalValue);
    case 'shadow':
      return formatShadowOriginalValue(originalValue);
    case 'transition':
      return `${originalValue.duration} ${formatCubicBezierOriginalValue(
        originalValue.timingFunction,
      )} ${originalValue.delay}`;

    // Types where value can be a string or number
    case 'fontWeight':
    case 'number':
      return originalValue.toString();

    // No formatting needed for these types
    case 'color':
    case 'duration':
    case 'dimension':
    case 'string':
    case 'strokeStyle':
      return originalValue;
    default: {
      const _exhaustiveCheck: never = type;
      return null;
    }
  }
}
